"use client";

import { cn } from "@peerigon/pupper/tailwind";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { useTranslations } from "next-intl";
import { useEffect, useRef, useState } from "react";
import { useWindowScroll } from "react-use";
import { getFragmentData, graphql } from "src/__generated__";
import { StickyPopupPosition } from "src/__generated__/graphql";
import XIcon from "src/assets/x-icon.svg";
import ContactFormContainer from "src/components/sections/ContactFormSection/ContactFormContainer";
import { Markdown } from "src/components/sections/MarkdownSection/MarkdownSection";
import type { FragmentType } from "src/__generated__";

export const StickyPopupSectionFragment = graphql(`
	fragment StickyPopupSectionItem on StickyPopupSection {
		id
		markdown
		displayContactForm
		contactEmailAddress
		position
	}
`);

const SCROLL_PERCENTAGE = 0.4;

export const StickyPopupSection: React.FC<{
	section: FragmentType<typeof StickyPopupSectionFragment>;
}> = ({ section }) => {
	const { markdown, displayContactForm, contactEmailAddress, position } =
		getFragmentData(StickyPopupSectionFragment, section);
	const t = useTranslations();
	const { y } = useWindowScroll();
	const [isOpen, setIsOpen] = useState(false);
	const [showSuccess, toggleShowSuccess] = useState(false);
	const popupDismissedRef = useRef(false);
	const portalContainerRef = useRef<HTMLDivElement | null>(null);
	const popupHasContent = Boolean(markdown ?? displayContactForm);
	const popupPosition = position ?? StickyPopupPosition.bottomCenter;

	useEffect(() => {
		const fortyPercentScrollHeight =
			document.body.scrollHeight * SCROLL_PERCENTAGE;
		const isPageLongEnough = fortyPercentScrollHeight > window.innerHeight;
		const scrolledTo = isPageLongEnough ? y : y + window.innerHeight;
		const showStickyPopup = scrolledTo > fortyPercentScrollHeight;

		if (showStickyPopup && popupHasContent && !popupDismissedRef.current) {
			setIsOpen(true);
		}
	}, [popupHasContent, y]);

	return (
		<section>
			<DialogPrimitive.Root open={isOpen} modal={false}>
				<DialogPrimitive.Portal container={portalContainerRef.current}>
					<DialogPrimitive.Content
						className={cn(
							"fixed bottom-0 z-header flex h-fit max-h-[60rem] min-h-[20rem] w-full flex-col justify-center overflow-hidden rounded-lg border border-gray-nav bg-white shadow-popup focus:!shadow-popup md:bottom-5 md:max-w-[40rem]",
							"animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 md:zoom-in-90 data-[state=open]:md:slide-in-from-bottom-0",
							{
								"left-[50%] translate-x-[-50%]":
									popupPosition ===
									StickyPopupPosition.bottomCenter,
							},
							{
								"right-0 md:right-5":
									popupPosition ===
									StickyPopupPosition.bottomRight,
							},
						)}
						onOpenAutoFocus={(e) => e.preventDefault()}
					>
						<div className="sr-only">
							<DialogPrimitive.Title>
								{t("stickyPopupTitle")}
							</DialogPrimitive.Title>
							<DialogPrimitive.Description>
								{t("stickyPopupDescription")}
							</DialogPrimitive.Description>
						</div>
						<div className="flex flex-col gap-4 overflow-y-auto p-10 text-left [&_form]:m-0">
							{markdown ? (
								<div
									className={cn(
										"[&_div]:last-of-type:m-0 [&_h2]:text-2xl [&_h2]:font-bold [&_h2]:leading-regular-3",
										"[&_h3]:mt-2 [&_h3]:text-xl [&_h3]:font-medium [&_h3]:leading-larger",
										"[&_p]:text-base [&_p]:font-light [&_p]:leading-larger [&_p]:first-of-type:mt-4",
										{
											"[&_h2]:text-center [&_h3]:text-center [&_p]:text-center":
												!displayContactForm,
										},
									)}
								>
									<Markdown source={markdown} />
								</div>
							) : null}

							{displayContactForm ? (
								<>
									{showSuccess ? (
										<div className="text-center text-base font-light leading-larger">
											{t("contactFormSuccessMessage")}
										</div>
									) : (
										<ContactFormContainer
											formName={`Paid Landing Page Form (${contactEmailAddress})`}
											onSuccess={() =>
												toggleShowSuccess(true)
											}
											hideCompanyField
											hidePhoneField
											hideMessageField
											hideNewsletterField
										/>
									)}
								</>
							) : null}
						</div>

						<DialogPrimitive.Close
							className={cn(
								"absolute right-[1rem] top-[1rem] inline-flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-full p-2 transition-colors",
								"focus-visible:!rounded-full focus-visible:!outline-1 focus-visible:!outline-black",
								"focus:rounded-full focus:outline-1 focus:outline-black",
								"enabled:hover:bg-gray-nav enabled:hover:text-black",
								"enabled:active:bg-black enabled:active:text-white",
								"disabled:cursor-not-allowed disabled:opacity-20",
							)}
							onClick={() => {
								setIsOpen(false);
								popupDismissedRef.current = true;
							}}
						>
							<XIcon className="h-4 w-4" />
						</DialogPrimitive.Close>
					</DialogPrimitive.Content>
				</DialogPrimitive.Portal>
			</DialogPrimitive.Root>
			<div ref={portalContainerRef} />
		</section>
	);
};
