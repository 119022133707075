import React from "react";
import { Components } from "react-markdown";
import Button from "../../Button/Button";
import Link from "../../Link/Link";

/**
 * if you want to use a button style link in markdown, do it like:
 * [[[button label]]](https://...)
 */
const LinkRenderer: Components["a"] = ({ href, children }) => {
	if (href === undefined) {
		return <>{children}</>;
	}

	const childStr = String(children);
	const isButton = childStr.startsWith("[[") && childStr.endsWith("]]");
	const linkLabel = isButton
		? childStr.substring(2, childStr.length - 2)
		: children;

	return isButton ? (
		<Button
			css={{ margin: `1rem 1rem 0 0` }}
			as={(props) => <Link {...props} to={href} />}
		>
			{linkLabel}
		</Button>
	) : (
		<Link to={href}>{linkLabel}</Link>
	);
};

export default LinkRenderer;
