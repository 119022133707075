"use client";

import { Box } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import ImageContainer from "./ImageContainer";
import { InfoBlockContainer } from "./InfoBlockContainer";
import { InfoElementContainer } from "./InfoElementContainer";
import { InfoElementHeaderContainer } from "./InfoElementHeaderContainer";
import { StyledIcon } from "./StyledIcon";
import { SmallText } from "../../../styles/FontFaces";
import { textPresets } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import Title from "../../Title/Title";
import { getImageAlt } from "../../server.helpers";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

const styleContentContainer = css`
	position: relative;
	align-items: center;
`;

export const InfoBlockSectionFragment = graphql(`
	fragment InfoBlockSectionItem on InfoBlockSection {
		__typename
		id
		anchor
		spacingTop
		spacingBottom
		image {
			...CmsImageFields
		}
		headline
		listItems {
			id
			infoText: text
			headline
			icon {
				...CmsImageFields
			}
		}
	}
`);

export const InfoBlockSection: React.FC<{
	section: FragmentType<typeof InfoBlockSectionFragment>;
}> = ({ section }) => {
	const { anchor, image, headline, listItems, spacingTop, spacingBottom } =
		getFragmentData(InfoBlockSectionFragment, section);
	const resolvedImage = getFragmentData(CmsImageFields, image);
	const alt = getImageAlt(resolvedImage);

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<Anchor id={anchor} />
			<Box position="relative">
				<ContentContainer css={styleContentContainer}>
					<InfoBlockContainer>
						<Title headingLevel="h3" margin="0 0 30px 0">
							{headline}
						</Title>
						{listItems.map(({ id, infoText, icon, headline }) => {
							const resolvedIcon = getFragmentData(
								CmsImageFields,
								icon,
							);

							return (
								<InfoElementContainer key={id}>
									{(headline || resolvedIcon) && (
										<InfoElementHeaderContainer>
											{resolvedIcon && (
												<StyledIcon
													src={resolvedIcon.url}
													loading="lazy"
													height={
														resolvedIcon.height ??
														"45px"
													}
													width={
														resolvedIcon.width ??
														"100%"
													}
													alt=""
												/>
											)}
											<Title
												headingLevel="h4"
												margin={icon ? `0 10px` : "0"}
												{...textPresets.size5}
											>
												{headline}
											</Title>
										</InfoElementHeaderContainer>
									)}
									<SmallText>{infoText}</SmallText>
								</InfoElementContainer>
							);
						})}
					</InfoBlockContainer>
				</ContentContainer>
				<ImageContainer style={{ height: "100%" }}>
					<CmsImage
						image={resolvedImage}
						alt={alt}
						style={{ height: "100%" }}
					/>
				</ImageContainer>
			</Box>
		</BaseSection>
	);
};
