import { cn } from "@peerigon/pupper/tailwind";
import { getFragmentData, graphql } from "src/__generated__";
import { PageType } from "src/__generated__/graphql";
import Link from "src/components/Link/Link";
import type { FragmentType } from "src/__generated__";
import type { FooterPageFieldsFragmentDoc } from "src/__generated__/graphql";

const FooterPageFieldsFragment = graphql(`
	fragment FooterPageFields on Page {
		routeName
		title
		shortTitle
		type
	}
`);

const FooterSecondaryLinks: React.FC<{
	type: PageType;
	pages: Array<FragmentType<typeof FooterPageFieldsFragmentDoc>>;
}> = ({ type, pages }) => {
	const pagesFragment = getFragmentData(FooterPageFieldsFragment, pages);

	return (
		<div
			className={cn(
				"flex flex-col",
				type === PageType.legal
					? "gap-3 md:flex-row md:gap-8"
					: "gap-4",
			)}
		>
			{pagesFragment.map((page) => {
				return (
					<Link
						key={page.routeName}
						to={page.routeName}
						className={cn(
							"text-base font-light disable-anchor-shadow-styles hover:underline focus:underline",
							type === PageType.legal
								? "whitespace-nowrap leading-larger"
								: "leading-regular-3",
						)}
					>
						{page.shortTitle ?? page.title}
					</Link>
				);
			})}
		</div>
	);
};

export default FooterSecondaryLinks;
