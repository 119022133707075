import { useParams } from "next/navigation";
import { startTransition, useCallback } from "react";
import { usePathname, useRouter } from "src/i18n/routing";
import type { Locale } from "src/i18n/routing";

export const useChangeLocale = (
	localizedPathname: Record<string, Record<string, string>>,
) => {
	const router = useRouter();
	const pathname = usePathname();
	const params = useParams();

	const onChangeLocale = useCallback(
		(newLocale: Locale) =>
			startTransition(() => {
				router.replace(
					{
						pathname:
							localizedPathname[
								// Remove trailing slash, see https://github.com/amannn/next-intl/issues/668
								pathname.replace(/\/$/, "")
							]?.[newLocale] ?? "/",
						// https://github.com/amannn/next-intl/blob/f2a4b76b2827c05decf2db638636e174df649d82/examples/example-app-router/src/components/LocaleSwitcherSelect.tsx#L28C9-L30C75
						// @ts-expect-error -- TypeScript will validate that only known `params`
						// are used in combination with a given `pathname`. Since the two will
						// always match for the current route, we can skip runtime checks.
						params,
					},
					{
						locale: newLocale,
					},
				);
			}),
		[localizedPathname, params, pathname, router],
	);

	return { pathname, onChangeLocale };
};
